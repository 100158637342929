import './Dsoa.css';
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { studentInfo as getStudentInfo } from 'dsoapi';
import CourseDetails from './CourseDetails';
import Header from './Header';
import Home from './Home';
import {
  Contact,
  Course,
  Dashboard,
  EditProfile,
  EmailSettings,
  ForgotPassword,
  GatherCourseInfo,
  HelpPage,
  HelpPageCategories,
  Login,
  LoginAsStudent,
  NotFound,
  OAuthGoogle,
  RegistrationSteps,
  Payment,
  UserContext,
  loggedIn,
  isAdmin,
} from 'dsoa-components';
import CoursesForState from './CoursesForState';
import DsoaBanner from './DsoaBanner';
import SignUp from './SignUp';
import Privacy from './Privacy';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.doSetStudentInfo = (info) => {
      this.setState({ studentName: info?.studentName });
      this.setState({ emailAddress: info?.emailAddress });
    };

    this.state = {
      studentName: '',
      emailAddress: '',
      setStudentInfo: this.doSetStudentInfo,
    };
  }

  checkToken = async () => {
    if (loggedIn()) {
      const response = await getStudentInfo();
      this.setState({ studentName: response.data.studentName });
      this.setState({ emailAddress: response.data.emailAddress });
    }
  };

  componentDidMount() {
    this.checkToken();
  }

  setTitle = (title) => {
    document.title = 'Driving School of America - ' + title;
  };

  render() {
    return (
      <div>
        <UserContext.Provider value={this.state}>
          <BrowserRouter>
            <Header />
            <Routes>
              <Route path="/" element={<Home setTitle={this.setTitle} />} />
              <Route
                path="/course/:courseAbbreviation/*"
                element={<CourseDetails setTitle={this.setTitle} />}
              />
              <Route
                path="/courseapp/:hashLink"
                element={
                  <RequireAuth>
                    <Course setTitle={this.setTitle} />
                  </RequireAuth>
                }
              />
              <Route
                path="/dashboard/*"
                element={
                  <RequireAuth>
                    <DsoaBanner text="Student Dashboard" />
                    <Dashboard setTitle={this.setTitle} />
                  </RequireAuth>
                }
              />
              <Route
                path="/editprofile"
                element={
                  <RequireAuth>
                    <EditProfile setTitle={this.setTitle} />
                  </RequireAuth>
                }
              />
              <Route
                path="/forgotpassword/*"
                element={
                  <ForgotPassword site="DSOA" setTitle={this.setTitle} />
                }
              />
              <Route
                path="/gatherinfo/:infoPage/:uceId"
                element={
                  <RequireAuth>
                    <GatherCourseInfo
                      showRegistrationSteps={true}
                      setTitle={this.setTitle}
                    />
                  </RequireAuth>
                }
              />
              <Route
                path="/help/:titleSlug"
                element={
                  <HelpPage
                    site="DSOA"
                    setTitle={this.setTitle}
                    banner={<DsoaBanner text="Driving School of America" />}
                  />
                }
              />
              <Route
                path="/help"
                element={
                  <HelpPageCategories
                    site="DSOA"
                    title="Help Pages"
                    setTitle={this.setTitle}
                    banner={<DsoaBanner text="Driving School of America" />}
                  />
                }
              />
              <Route
                path="/login"
                element={<Login setTitle={this.setTitle} />}
              />
              <Route
                path="/loginasstudent"
                element={
                  <RequireAdminAuth>
                    <LoginAsStudent setTitle={this.setTitle} />
                  </RequireAdminAuth>
                }
              />
              <Route
                path="/payment/:uceId"
                element={
                  <RequireAuth>
                    <Payment
                      showRegistrationSteps={true}
                      siteName="Driving School of America"
                      setTitle={this.setTitle}
                    />
                  </RequireAuth>
                }
              />
              <Route
                path="/oauth2/google/*"
                element={<OAuthGoogle site="DSOA" />}
              />
              <Route
                path="/signup"
                element={
                  <SignUp
                    registrationSteps={
                      <RegistrationSteps step={2} linkSelectCourse={true} />
                    }
                    setTitle={this.setTitle}
                  />
                }
              />
              <Route
                path="/state/:stateAbbreviation"
                element={<CoursesForState setTitle={this.setTitle} />}
              />
              <Route
                path="/contact"
                element={<Contact site="DSOA" setTitle={this.setTitle} />}
              />
              <Route
                path="/emailsettings/:emailLogId"
                element={<EmailSettings setTitle={this.setTitle} />}
              />
              <Route
                path="/privacy"
                element={<Privacy setTitle={this.setTitle} />}
              />
              <Route
                path="*"
                element={<NotFound redirectUrl="/" setTitle={this.setTitle} />}
              />
            </Routes>
          </BrowserRouter>
        </UserContext.Provider>
      </div>
    );
  }
}

const RequireAuth = ({ children }) => {
  return loggedIn() ? children : <Navigate to="/" />;
};

const RequireAdminAuth = ({ children }) => {
  return isAdmin() ? children : <Navigate to="/" />;
};

export default App;
