import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { studentEmailOptOut, studentEmailOptOutToggle } from 'dsoapi';

const EmailSettings = ({ setTitle = () => {} }) => {
  const { emailLogId } = useParams();
  const navigate = useNavigate();
  const [emailReminders, setEmailReminders] = useState(undefined);
  const [emailAddress, setEmailAddress] = useState(undefined);

  setTitle('Email Settings');

  useEffect(() => {
    if (!emailLogId) {
      navigate('/');
    }
    const fetchEmailOptOut = async () => {
      const response = await studentEmailOptOut(emailLogId);
      if (!response) {
        navigate('/');
      }
      setEmailReminders(!response.data.optOut);
      setEmailAddress(response.data.email);
    };
    fetchEmailOptOut();
  }, [emailLogId]);

  const handleToggleChange = async () => {
    setEmailReminders(!emailReminders);
    await studentEmailOptOutToggle(emailLogId);
  };

  if (emailReminders === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <div
      className="ui middle aligned center aligned grid"
      style={{ height: '100%' }}
    >
      <div className="column" style={{ maxWidth: '450px' }}>
        <h3 className="ui image header">
          <div className="content" style={{ paddingTop: '10px' }}>
            Email Settings for {emailAddress}
          </div>
        </h3>
        <div className="ui divider" style={{ paddingBottom: '10px' }}></div>
        <div className="ui toggle checkbox">
          <input
            type="checkbox"
            name="public"
            checked={emailReminders}
            onChange={handleToggleChange}
          />
          <label>Email Reminders</label>
        </div>
      </div>
    </div>
  );
};

export default EmailSettings;
